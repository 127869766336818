import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em, rem, clearFix } from "polished"

import Layout from "../components/layout"
import Meta from "../components/meta"
import Styled from "../components/styled"
import { Heading1 } from "../components/styled/heading"
import { ReactComponent as SvgLogoMark } from "../assets/images/icons/logo-mark.svg"

const Container = styled.div`
  ${({ theme }) => theme.grid.container()}

  padding-bottom: ${rem(240)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-bottom: ${rem(120)};
  }
`

const Text = styled(Styled)`
  padding: ${rem(60)};
  column-count: 2;
  column-gap: ${rem(100)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: 0;
    padding-top: ${em(20)};
    column-count: 1;
  }

  > * {
    width: 100%;
    display: inline-block;
  }
`

const LogoMark = styled.div`
  margin-bottom: ${rem(80)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.yellowLighter};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
    margin-bottom: ${rem(40)};
  }

  svg {
    width: ${em(70)};
    height: ${em(70)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: ${em(40)};
      height: ${em(40)};
    }
  }
`

const Cols = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.mediumDown} {
    flex-direction: column;
  }

  > div {
    ${clearFix()}

    width: calc(50% - ${rem(50)});
    flex-shrink: 0;
    position: relative;

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: 100%;
    }
  }

  .gatsby-image-wrapper {
    width: 55vw;
    max-width: ${rem(960)};
    float: right;

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: 100%;
      margin-bottom: ${em(40)};
      float: none;
    }
  }
`

const ApiePage = ({ data }) => {
  return (
    <Layout>
      <Meta
        data={{
          title: `Apie`,
          description: `Viešoji įstaiga Mindify yra ne pelno siekianti organizacija.
            Pagrindinė veikla nukreipta į vaikų ir jaunimo neformalaus ugdymo
            organizavimą bei įvairių socialinių iniciatyvų įgyvendinimą`,
        }}
      />

      <Container>
        <Heading1>Apie</Heading1>

        <Text>
          <p>
            Viešoji įstaiga Mindify yra ne pelno siekianti organizacija.
            Pagrindinė veikla nukreipta į vaikų ir jaunimo neformalaus ugdymo
            organizavimą bei įvairių socialinių iniciatyvų įgyvendinimą.
            Organizacija vykdo visuomeninę, kultūrinę ir švietėjišką veiklą,
            kurios spektras susideda iš interaktyvių edukacinių užsiėmimų
            vaikams, paaugliams ir jaunimui, meninių programų įgyvendinimo, bei
            sveikos gyvensenos diegimo.
          </p>

          <p>
            Šiandien vis dažniau kalbame apie sveikatą, jos stiprinimą,
            sveikatos problemas bei sveiko gyvenimo būdo nuostatų formavimo(si)
            svarbą vaikystėje, paauglystėje ir jaunystėje. Naujausių tyrimų
            duomenimis, kasmet blogėja Lietuvos vaikų sveikata, mūsų šalies
            vaikai jaučiasi gerokai nelaimingesni negu kitų Europos šalių
            vaikai, blogiau vertina savo sveikatą. Kodėl taip yra? Galima
            teigti, kad viena svarbiausių priežasčių yra ta, kad ilgą laiką mūsų
            šalyje sveikatos saugojimo kompetencijos ugdymui nebuvo skiriamas
            pakankamas dėmesys. Medikai, mokytojai ir tėvai ilgą laiką
            didžiausią dėmesį skyrė fizinei sveikatai. Palikdami nuošaly dvasinę
            sveikatos sritį, nekreipdami dėmesio į vaiko emocinę būseną, nors ji
            yra neatsiejama sveikatos dalis. Tą patvirtina nūdienos tyrimų
            duomenys: Lietuva pirmauja pagal savižudybių skaičių.
          </p>

          <p>
            Gana plačiai paplitę ir psichosomatinės sveikatos negalavimų
            požymiai: vienišumas, depresiškumas, stresas. Didelis procentas mūsų
            šalies vaikų dažnai vartoja vaistus. Tai rodo, kad valstybė ir
            visuomenė turi daugiau skirti dėmesio vaikų emocinei sveikatai
            stiprinti.
          </p>

          <p>
            <strong>
              Suprantant vaikų sveikatos saugojimo ir stiprinimo reikšmę,
              Mindify savo veiklomis suteikia ugdytiniams žinias apie sveiką
              gyvenimo būdą, padeda suprasti sveiko gyvenimo būdo privalumus,
              kūrybiniais sprendimais ugdo sveikos gyvensenos įgūdžius ir
              įpročius, bei diegia sveikatinimo profilaktiką, kaip vieną
              svarbiausių vertybių žmogaus gyvenime. To siekia bendradarbiaujant
              su tėvais ar įtėviais, mokytojais ir globėjais.
            </strong>
          </p>

          <p>
            Mindify misija yra profesionalių visuomenininkų, kultūrininkų,
            kineziterapeutų, psichologų, dietistų komandos pagalba formuoti
            sveika gyvensena paremtą holistinę asmens gerovės “grandinę”:
            sveikas paauglys – laiminga šeima – tvari visuomenė. Suburta
            profesionali komanda dirba tiek su vaikų ir paauglių grupėmis,
            individualiai, tiek veda įvairius mokymus priešmokyklinio ir
            bendrojo ugdymo mokytojams.
          </p>
        </Text>

        <LogoMark>
          <SvgLogoMark aria-hidden="true" />
        </LogoMark>

        <Cols>
          <div>
            <GatsbyImage
              image={data.image.childImageSharp.gatsbyImageData}
              alt="Inovatyvus švietimas vaikams ir jaunimui"
            />
          </div>

          <Styled>
            <p>
              <strong>Laura Preikšaitienė</strong> — nevyriausybinės
              organizacijos Mindify steigėja, psichologė, būsima egzistencinės
              krypties psichoterapeutė (HEPI). Aktyvi, įvairių ne pelno
              siekiančių iniciatyvų autorė, nuo 2020 metų parengė ir įgyvendino
              daugiau kaip 60 projektų. Įstaigos veiklos tikslai: neformalus
              ugdymas. Prevencinė šviečiamoji veikla vaikams ir jaunimui,
              vyresnio amžiaus žmonėms palankių sveikatinimo veiklų taikymas.
              Įgyvendindama projektus Laura siekia perteikti holistinio gyvenimo
              būdo propagavimo metodus ir daugialypę to naudą. Matydama
              edukacinės veiklos kuriamą vertę ilgalaike perspektyva - į
              projektines veiklas įtraukia ir negalią turinčius asmenis bei
              pažeidžiamų socialinių grupių vaikus.
            </p>
          </Styled>
        </Cols>
      </Container>
    </Layout>
  )
}

ApiePage.propTypes = {
  data: PropTypes.object,
}

export default ApiePage

export const pageQuery = graphql`
  query {
    image: file(
      sourceInstanceName: { eq: "content-images" }
      relativePath: { eq: "laura.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
